import axios from 'axios';
import { url as apiUrl, setHeaders } from '../api';
import { toast } from 'react-toastify';




// it will return a URL object or null
const fetchIcon = async (code:string) => {
    try {
        const headers = setHeaders({ responseType: 'blob' });
        const response = await axios.get(
            `${apiUrl}/api/icons/icon/${code}`,
            headers
        );
        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        });
        const imageObjectUrl = URL.createObjectURL(blob);
        return imageObjectUrl;
    } catch (error) {
        console.error('Error fetching badge image:', error);
        toast.error('Failed to fetch badge image');
    }
}

const uploadIcon = async (icon: File) => {
    try {
        const headers = setHeaders({ 'Content-Type': 'multipart/form-data' }); 
        const formData = new FormData();
        formData.append('icon', icon);
        
        const response = await axios.post(
            `${apiUrl}/api/icons/upload`,
            formData,
            headers
        );
        
        return response.data;
    } catch (error) {
        console.error('Error uploading icon:', error); // updated error message
        toast.error('Failed to upload icon'); // updated toast message
    }
}

const listIcons = async () => {
    try {
        const headers = setHeaders();
        const response = await axios.get(
            `${apiUrl}/api/icons/list`,
            headers
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching badge image:', error);
        toast.error('Failed to fetch badge image');
    }
}

const deleteIcon = async (code: string) => {   
    try {
        const headers = setHeaders();
        await axios.delete(
            `${apiUrl}/api/icons/icon/${code}`,
            headers
        );
        return;
    } catch (error) {
        console.error('Error deleting icon:', error);
        toast.error('Failed to delete icon');
    }
}

export {fetchIcon, uploadIcon, listIcons, deleteIcon};
