/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Routes, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import propTypes from "prop-types";

// import pages
import HomePage from "../home_page/home";
import AboutPage from "../about_page/about";
import ContactPage from "../contact_page/contact";
import StorePage from "../store_page/store_page";
import GalleryPage from "../gallery_page/gallery";
import LoginPage from "../authenticaton/login";
import SignupPage from "../authenticaton/signup";
import ErrorPage from "../error_page/error";
import AddArt from "../gallery_page/add_item";

import Header from "../site_header/site_header";

// import checkIsAuthenticated utility function
import { checkIsAuthenticated } from "../../lib/utilities";
import ProfilePage from "../profile_page/profilePage";
import ChatBot from "../chat/chat";
import ChatBubble from "../chat/chatBubble";
import UsersStats from "../stats_page/userStats";
import ArtOfTheDayPage from "../art_of_the_day/aotd_page";
import ArtGrid from "../aotd-gallery/ArtGrid";
import BirthdayEva from "../eva-birthday/eva";
import QRNotes from "../qRNotes/qRNotesPage";
import RecipePage from "../recipe_page/recipe_page";
import ResetPasswordPage from "../authenticaton/resetPassword";
import ForgotPasswordPage from "../authenticaton/forgotPassword";
import PartyInvite from "../random/partyInvite";
import UnsubPage from "../random/unsubPage";
import CoffeeCard from "../coffee_card/CoffeeCard";
import Footer from "../site_footer/site_footer";
import BadgeAdminPage from "../badges/badges_admin/badgeAdminPage";
import BadgePage from "../badges/badges_page/badgePage";
import IconsPage from "../icons/iconsPage";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// define OnlyForAuthenticatedRoute component
// const OnlyForAuthenticatedRoute = ({component: Component, isAuthenticated, ...rest}) => (
//     <Route
//         {...rest}
//         render={(props) =>
//             (isAuthenticated ? <Component {...props} /> : <Redirect to="/" />)}
//     />
// );

// define OnlyForGuestRoute component
// const OnlyForGuestRoute = ({component: Component, isAuthenticated, ...rest}) => (
//     <Route
//         {...rest}
//         render={(props) =>
//             (isAuthenticated ? <Redirect to="/home" /> : <Component {...props} />)}
//     />
// );

// function RequireAuth({ children, redirectTo }) {
// 	let isAuthenticated = getAuth();
// 	return isAuthenticated ? children : <Navigate to={redirectTo} />;
// }

// define PublicRoute component
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    element={<Component />}
  // render={(props) => <Component {...props} />}
  />
);

function CustomRouter() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  if (window.location.pathname.startsWith('/coffee-card-generator')) {
    return (
      <Routes>
        <Route path="/coffee-card-generator/:param" element={<CoffeeCard />} />;
      </Routes>
    );
  }

  return [
    <>
      <Header />
      <ChatBubble />
      <div>
        {/* <GoogleReCaptchaProvider reCaptchaKey="6LehcRYpAAAAAHqR4vCz2n8VkGtlP_x2XCvEFqYP"> */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/art" element={<GalleryPage />} />
            <Route path="/art/add" element={<AddArt />} />
            <Route path="/marketplace" element={<StorePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<SignupPage />} />
            <Route path="/chat" element={<ChatBot />} />
            <Route path="/user-stats" element={<UsersStats />} />
            <Route path="/art-of-the-day" element={<ArtOfTheDayPage />} />
            <Route path="/art-of-the-day/gallery" element={<ArtGrid />} />

            <Route path="/cookbook/:title" element={<RecipePage />} />
            <Route path="/cookbook" element={<RecipePage />} />

            <Route path="/reset-pw" element={<ResetPasswordPage />} />
            <Route path="/forgot-pw" element={<ForgotPasswordPage />} />

            <Route path="/vako/birthday/aaa/i-mikri-mou-eva" element={<BirthdayEva />} />

            <Route path="/qrnotes" element={<QRNotes />} />

            <Route path="/party" element={<PartyInvite />} />
            <Route path="/unsubscribe" element={<UnsubPage />} />

            <Route path="/coffee-card-generator/:param" element={<CoffeeCard />} />

            <Route path="/badges-admin" element={<BadgeAdminPage />} />
            <Route path="/badges" element={<BadgePage />} />

            <Route path="/icons-admin" element={<IconsPage />} />

            <Route path="*" element={<ErrorPage />} />
          </Routes>
        {/* </GoogleReCaptchaProvider> */}
      </div>
      <Footer />
    </>,
  ];
}

export default CustomRouter;
