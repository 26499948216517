import React, { useEffect, useState, FC, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import "./aotd.css";
import "./spinner.css";
import { url } from "../../api";
import axios, { AxiosResponse } from "axios";
import { logAOTDView } from "../../analytics/GoogleAnalytics";
import styled from "styled-components";
import { logPageView } from "../../analytics/GoogleAnalytics";
import { toast } from "react-toastify";
import "./spinner.css";
import VerificationComponent from "../verification/verification";
import { fetchIcon } from "src/utils/icons";

interface ArtOfTheDayProps {
  onLoadingChange: (loading: boolean) => void;
}

const QuoteForm = styled.form`
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
`;

const QuoteInput = styled.input`
  font-size: 1rem;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.1rem;
`;

const SubmitButton = styled.button`
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  box-shadow: 5px 5px rgba(0, 0, 0, 0.818);
  background-color: #444;
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #222;
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  @media (max-width: 700px) {
    /* display: block; */
    grid-template-columns: repeat(1, 2fr);
    gap: 10px;
    /* margin: 5px; */
  }
`;


const HelpButton = styled.button<{ quoteHelp: number }>`
  background-color: ${(props) =>
    props.quoteHelp >= 3 ? "#a4a4a4" : "#009f6f"};
  grid-column: span 1;
  color: rgb(249, 249, 249);
  border: 1px solid rgb(35, 35, 35);
  padding: 10px;
  /* box-shadow: 5px 5px rgba(0, 0, 0, 0.818); */
  box-shadow: ${(props) =>
    props.quoteHelp >= 3 ? "" : "5px 5px rgba(0, 0, 0, 0.818)"};
  margin: 10px;
  justify-content: start;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 186px;
  transition-duration: 0.4s;

  &:hover {
    background-color: ${(props) =>
    props.quoteHelp >= 3 ? "#a4a4a4" : "#006d4c"};
    box-shadow: ${(props) =>
    props.quoteHelp >= 3 ? "" : "0 2px 4px rgba(0, 0, 0, 0.1)"};
  }
`;

const SignupMessage = styled.div`
  font-size: 1rem;
  font-weight: 900;
  color: #323232;
  text-align: start;
  margin-top: 2rem;
`;

const loadingMessages = [
  "Great art takes time... just like this loading screen.",
  "Patience is a virtue, but if you don't have it, we still like you.",
  "Just like fine wine, this content is worth the wait.",
  "Hold on, we're making sure the pixels are perfectly aligned.",
  "Almost there, we're just teaching the pixels to behave.",
  "Hang tight! Our server hamsters are running as fast as they can.",
  "Looking for the art? It's currently hiding from us too.",
  "If you stare at the spinner long enough, it starts to spin the other way... or does it?",
  "Our art is like a good joke; it takes a moment to get it.",
  "Tightening the last pixel. Nope, not a screwdriver job!",
  "Arranging the pixels. It's like herding cats, but more colorful.",
  "We're testing the canvas's patience. It's a very tense material.",
  "Getting the art just right. Unlike my coffee, which is always wrong.",
  "If this loading was a painting, it'd be called 'Still Waiting'.",
  "Why did the pixel go to art school? To get a little culture!",
  "Our pixels are on a break. Even Mona Lisa needed stretch breaks.",
  "Adding a bit of suspense... It's an art in itself, isn't it?",
  "Ever heard of the artist who was also a gardener? He made hedge art.",
  "We asked the pixels to form a line, but they got all graphic instead.",
  "This art will load in no time... if you're a snail.",
  "The server is painting at a Van Gogh pace: Impressionist, not hurried.",
  "Don't rush art. Unless it's a brush. They need to be quick.",
  "The pixels are staging a sit-in. They demand more RGB!",
  "How does the ocean pay for its art supplies? With sand dollars.",
  "Our loading spinner is a big fan of revolutions. Get it?",
  "Why did the picture go to jail? Because it was framed!",
  "We’re layering the canvas. It's quite the cover-up story.",
  "Art loading: Please easel into the experience.",
  "We’re blending the colors. It's a mix of emotions, really.",
  "Why don't we play hide and seek with the art? Good luck hiding a canvas.",
  "We're layering the digital varnish. Watch out for virtual fumes!",
  "Why did the artist use a browser? To draw the web.",
  "We're now mixing the primary colors. It's a prime time!",
  "Our pixels are going through art school. Expect some abstract loading!",
  "Crossword for the art: 4 letters, starts with 'L', ends with 'D'.",
  "The art is loading in 4D: Definitely Delayed Digital Doodles.",
  "Did you hear about the claustrophobic astronaut? He just needed a little space.",
  "What’s orange and sounds like a parrot? A carrot in art class!",
  "How do artists greet each other? 'Yellow, nice to paint you!'",
  "The art is almost here, it's just not quite 'canvas-ready'.",
  "Telling the pixels a bedtime story. They're about to be dreamy!",
  "Why was the computer bad at art? It kept losing its bits.",
  "Loading your art: It's like cooking, but without the smell.",
  "If this loading was a dance, it'd be the 'buffer boogie'.",
  "The pixels are taking a short art nap. Dreaming of surrealism.",
  "How does Salvador Dali start his mornings? With a surreal bowl of cereal.",
  "We're currently negotiating with the pixels. They want artistic freedom.",
  "Why did the canvas go to therapy? It had too many layers.",
  "Art loading... Please don't brush us off!",
  "We're teaching the colors how to blend. It's a real mixer.",
  "Why was the artist always calm? He knew how to brush off stress.",
  "We're on the final brush stroke. Well, maybe a few more strokes.",
  "Our pixels are currently in a meeting, discussing shades and tones.",
  "What did one painter say to another? 'I'll see you in the next layer.'",
  "If this loading screen was a work of art, it'd be in the 'forever lost' collection.",
  "If you think this is slow, you should see paint dry.",
  "Our pixels are lost in existential thought. Bear with us.",
  "Loading art... or contemplating the void. Hard to tell.",
  "Each pixel is undergoing an identity crisis. It's a phase.",
  "The art's loading as fast as it can. It's not immortal, you know.",
  "We're waiting for the pixels to finish their melodrama.",
  "Hang tight, we're just negotiating with some rebellious colors.",
  "If this load time was a book, it'd be 'War and Peace'.",
  "Our server is pondering the meaning of its existence. Again.",
  "Art loading... contemplating the futility of digital existence.",
  "The pixels are having a mid-life crisis. Just a sec.",
  "Why rush? In the grand scheme, time is an illusion.",
  "Our digital canvas is currently going through an art crisis.",
  "Loading... like Sisyphus, forever pushing that boulder.",
  "The spinner spins, uncaring of our mortal impatience.",
  "Art is eternal. This loading time just feels like it.",
  "The art is stuck in traffic. Probably a metaphysical jam.",
  "This isn’t slow loading; it’s a lesson in patience.",
  "Our pixels are staging a slow-down protest. Artistic expression!",
  "Loading... Maybe it’s time to reflect on our choices.",
  "The art is being a diva. Refuses to show up early.",
  "Waiting for the pixels to get over their existential dread.",
  "Like all good things, this loading takes forever.",
  "Our art is fashionably late. It's wearing its finest pixels.",
  "n450s insists on perfection. Hence, the artful wait.",
  "Loading art... as n450s whispers wisdom to each pixel.",
  "Our server is reading existential poetry. It's a moody artist.",
  "Art in progress... n450s is adding a touch of genius.",
  "Like a digital Prometheus, n450s brings fire to these pixels.",
  "n450s said, 'Let there be art.' And there was... eventually.",
  "In the realm of pixels, n450s is the reigning monarch.",
  "We asked n450s for speed, but got brilliance instead.",
  "Every pixel is handpicked by n450s. Quality over speed!",
  "The art is brooding... a reflection of its creator, n450s.",
  "Loading... n450s is still convincing the pixels to behave.",
  "Patience, the art is being sculpted by your digital chisel.",
  "n450s is weaving a tapestry of pixels. A moment, please.",
  "The pixels are staging a sit-in for more creative input from n450s.",
  "If this loading time was a novel, n450s would be the protagonist.",
  "Awaiting the grand reveal of your latest digital conquest.",
  "n450s is currently teaching the art the meaning of haste.",
  "Art loading: n450s's magnum opus takes time to unveil.",
  "Each pixel is an obedient subject in n450s's digital kingdom.",
  "n450s is sprinkling a little darkness, for flavor.",
  "The art is as deep and complex as your imagination.",
  "n450s is currently negotiating with the stubborn shades of gray.",
  "Sometimes art hides in the shadows. n450s is fetching a flashlight.",
  "The art’s not slow; it’s just fashionably late, à la n450s.",
  "Like a digital wizard, n450s is conjuring up pixels from the ether.",
  "The canvas is blank, but your mind is a storm of color.",
  "n450s is currently playing chess with the color palette. King me!",
  "Even the code is awed by your creativity. It's taking notes.",
  "n450s whispers to the art, and slowly, it awakens from slumber.",
  "It's not loading; it's an intermission curated by n450s.",
  "The pixels are aligning, aligning... and n450s watches, amused.",
  "Our art, much like n450s, doesn’t rush for anyone.",
  "Each pixel is a universe. n450s is the cosmic architect.",
  "The digital canvas: n450s’s realm, where time bends to will.",
  "We’re not stalling; we’re philosophizing about art, like n450s.",
  "n450s is playing a symphony, and each note is a pixel.",
  "In the gallery of the web, n450s’s art takes the center stage.",
  "The art's brewing. n450s says good things take time, like dark coffee.",
  "A pixel fell over. n450s is helping it back up.",
  "Loading... n450s is currently taming a wild color palette.",
  "Every pixel is a thought from n450s, deep and unfathomable.",
  "n450s is adding a sprinkle of enigma to each pixel.",
  "The art is marinating in n450s’s creative juices. Almost done!",
  "The pixels are debating existentialism. n450s is moderating.",
  "Art loading... n450s is perfecting the imperfections.",
  "In the digital abyss, n450s is the guiding light for lost pixels.",
  "The colors are having a mood swing. n450s is the therapist.",
  "n450s is the conductor, and the pixels are the orchestra.",
  "The art is a dance, choreographed by n450s.",
  "n450s is the artist, and the pixels are the paint.",
  "The art is a symphony, composed by n450s.",
  "n450s is the architect, and the pixels are the blueprint.",
  "The pixels are the actors, and n450s is the director.",
];

const ArtOfTheDay: FC<ArtOfTheDayProps> = ({ onLoadingChange }) => {
  const [artUrl, setArtUrl] = useState<string | null>(null);
  const [artTitle, setArtTitle] = useState<string | null>(null);
  const [artAuthor, setArtAuthor] = useState<string | null>(null);
  const [artQuote, setArtQuote] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [fetched, setFetched] = useState<boolean>(false);
  const [quoteHelp, setQuoteHelp] = useState<number>(0);
  const [currentStreak, setCurrentStreak] = useState<number>(0);
  const [isSignedUp, setIsSignedUp] = useState<boolean>(Boolean(localStorage.getItem("token")));

  const [streakIcon, setStreakIcon] = useState<URL | null>(null);

  const [exists, setExists] = useState<boolean | null>(true);

  const [quote, setQuote] = useState<string>("");
  const [quoteFound, setQuoteFound] = useState(true);
  // let quoteSet = false;
  // const [quoteSet, setQuoteSet] = useState(false);
  const [permission, setPermission] = useState(false);
  const [noVerify, setNoVerify] = useState(false);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const location = useLocation();

  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[Math.floor(Math.random() * loadingMessages.length)]);

  useEffect(() => {
    const messageInterval = setInterval(() => {
      // Choose a random index for the next message
      const randomIndex = Math.floor(Math.random() * loadingMessages.length);
      setLoadingMessage(loadingMessages[randomIndex]);

    }, 8000); // Change the message every 6 seconds

    return () => clearInterval(messageInterval); // Cleanup the interval on component unmount
  }, []);

  useEffect(() => {
    const fetchStreakIcon = async () => {
      try {
        const iconURL = await fetchIcon("STREAK_ICON");
        if (iconURL) {
          setStreakIcon(new URL(iconURL));
        }
      } catch (error) {
        console.error("Error fetching streak icon:", error);
      }
    };

    fetchStreakIcon(); // call the function to fetch the icon when the component mounts
  }, []); // empty dependency array ensures this runs only once on mount

  // get aotd
  useEffect(() => {
    const fetchArtOfTheDay = async () => {
      onLoadingChange(true);
      if (!fetched) {
        setLoading(true);
        onLoadingChange(loading);
        try {
          const response = await axios.get(url + `/api/art-of-the-day/today?_=${new Date().getTime()}`, {
            responseType: "blob",
            headers: {
              "x-auth-token": localStorage.getItem("token"),
            },
          });
          if (response.status === 201) {
            setIsSignedUp(true);
            setNoVerify(true);
            setLoading(false);
            onLoadingChange(loading);
          }
          else if (response.status === 200) {
            const imageUrl = URL.createObjectURL(response.data);
            setIsSignedUp(true);
            setNoVerify(false);
            setArtUrl(imageUrl);
            setFetched(true);
            setLoading(false);
            onLoadingChange(false);
          }
          else if (response.status === 245) {
            const imageUrl = URL.createObjectURL(response.data);
            setIsSignedUp(false);
            setNoVerify(false);
            setArtUrl(imageUrl);
            setLoading(false);
            onLoadingChange(false);
          }
        } catch (error) {
          console.error("Error fetching Art of the Day:", error);
          if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
              setIsSignedUp(false);
            } else {
              setServerError(true);
            }
          }
        } finally {
        }
      }
    };
    if (!fetched) {
      fetchArtOfTheDay();
    }
  }, [location, fetched, onLoadingChange]);

  useEffect(() => {
    const checkExistence = async () => {
      try {
        const response = await axios.get(url + `/api/art-of-the-day/info`);
        if (response.data.message) {
          setExists(false);
        } else {
          setExists(true);
          setArtTitle(response.data.title);
          setArtAuthor(response.data.createdBy);
          setArtQuote(response.data.authorQuote);
          setCurrentStreak(response.data.currentStreakNum);
          setQuoteHelp(parseInt(response.data.quoteHelp));
        }
      } catch (error) {
        console.error("Error fetching INFO:", error);
        setServerError(true);
      }
    };
    checkExistence();
  }, [location, fetched]);


  // get permission
  useEffect(() => {
    const checkPermission = async () => {
      try {
        const response = await axios.get(url + `/api/art-of-the-day/permission?_=${new Date().getTime()}`, {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        });
        if (response.status === 200) {
          setPermission(true);
        }
        else {
          setPermission(false);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error getting permissions:", error);
        if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
          setPermission(false);
        } else {
          // setServerError(true);
        }
      }
    };
    checkPermission();
  }, [fetched]);


  // get the quote
  useEffect(() => {

    const fetchQuote = async () => {
      try {
        const response = await axios.get(url + `/api/art-of-the-day/info`);
        if (!response.data.authorQuote) {
          setQuoteFound(false);
          setQuoteHelp(response.data.quoteHelp);
        }
        if (response.data.authorQuote) {
          setQuote(response.data.authorQuote);
          setQuoteFound(true);
          setQuoteHelp(response.data.quoteHelp);
        }
      } catch (error) {
        console.error("Error getting info 2:", error);
      }
    };
    fetchQuote()
  }, [loading, artQuote]);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        url + "/api/art-of-the-day/quote",
        {
          quote: quote,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      toast("you've set the quote", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      setArtQuote(quote);
      setQuoteFound(true);
    } catch (error) {
      console.error("Error submitting quote:", error);
      // setServerError(true);
    }
  };

  const getSuggestion = async (e: React.FormEvent) => {
    setSuggestionLoading(true);
    e.preventDefault();
    try {
      const response = await axios.post(
        url + "/api/art-of-the-day/suggest-quote",
        {
          quote: quote,
        },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setQuote(response.data.suggestedQuote);
      setQuoteHelp(response.data.quoteHelp);
      setSuggestionLoading(false);
    } catch (error) {
      console.error("Error asking for help:", error);
      toast.error("can't ask for help", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      // alert("An error occurred while submitting the quote.");
    }
  };

  const handleQuoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuote(e.target.value);
  };

  useEffect(() => {
    logAOTDView(artTitle);
  }, [artTitle]);

  if (serverError) {
    return (
      <SignupMessage>
        A server error occured...
      </SignupMessage>
    )
  }
  else if (noVerify) {
    return (
      <SignupMessage style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        You need to verify your email to generate art.
        <br />
        <div style={{ marginTop: "1rem" }}>
          <VerificationComponent />
        </div>
      </SignupMessage>
    )
  }
  else if (isSignedUp) {
    return (
      <div className="art-of-the-day" >
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
            {!exists && (
              <div>
                <div style={{ fontWeight: "900" }}>
                  <p>{loadingMessage}</p>
                </div>
                <br />
                It seems that you are the author!
                <br />
                Wait for your art to be generated!
                <br />
              </div>
            )}
          </div>
        ) : (

          artUrl ? (
            <div className="art-box">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold">{artTitle}</h3>
                {streakIcon && currentStreak > 3 && (
                  <div
                    className="streak-container"
                    style={{
                      display: 'inline-block',
                      // marginLeft: '1rem',
                      verticalAlign: 'middle',
                      position: 'relative',
                    }}
                  >
                    <img
                      src={streakIcon.toString()}
                      alt="Streak Icon"
                      style={{
                        width: '1.4rem',
                        height: '1.4rem',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        objectFit: 'contain',
                      }}
                      className="shadow-md"
                    />
                    <span style={{
                      // marginLeft: '0.5rem', 
                      verticalAlign: 'middle' 
                    }}>
                      {currentStreak}
                    </span>
                  </div>
                )}
              </div>
              <div>
                <img src={artUrl} alt={`Art of the day: ${artTitle}`} />
              </div>
              <div className="image-caption">
                <em>author: art initialized by {artAuthor}</em>
              </div>
              {artQuote && (
                <div className="quote">
                  <br />
                  {artQuote}
                  <span className="author">- {quoteHelp === 0 || !quoteHelp ? artAuthor : `${artAuthor} & AI`}</span>
                </div>
              )}
            </div>
          )

            : null)}
        {permission && !quoteFound && (
          <QuoteForm onSubmit={handleSubmit}>
            <p style={{ fontWeight: "900", fontSize: "1.2rem", color: "#b236c5" }}>Say hi to Twitter! </p>
            <br />
            <QuoteInput
              type="text"
              value={quote}
              maxLength={45}
              onChange={handleQuoteChange}
              placeholder="Enter your quote here..."
            />
            <CharacterCounter count={quote.length} maxCount={45} />
            <ButtonContainer>
              <button className="submitButton" type="submit">
                Submit Quote
              </button>
              <HelpButton
                disabled={quoteHelp >= 3}
                quoteHelp={quoteHelp}
                onClick={getSuggestion}
              >
                {suggestionLoading ? (
                  <div className="spinner-in-button"></div>
                ) : (
                  `AI Help (${3 - quoteHelp} left)`
                )}
              </HelpButton>
            </ButtonContainer>
          </QuoteForm>
        )}
      </div>
    );
  }
  else {
    return (
      <div className="art-of-the-day">
        {loading ? (
          <div className="spinner-container">
            {!exists ? (
              <SignupMessage>
                You need to be logged in to generate art.
                <br />
                You have time to sign up and be the author!
                <br />
                <a href="/signup">Sign up now!</a>
              </SignupMessage>
            ) : (
              <div className="spinner"></div>
            )}
          </div>
        ) : (
          artUrl ? (
            <div className="art-box">
              <div className="flex items-center space-x-2">
                <h3>{`${artTitle}`}</h3>
                {streakIcon && (
                  <div className="flex items-center space-x-1">
                    <span className="text-sm">{currentStreak} day streak</span>
                    <img src={streakIcon.toString()} alt="Streak Icon" className="w-4 h-4 inline-block" />
                  </div>
                )}
              </div>
              <div>
                <img src={artUrl} alt={`Art of the day: ${artTitle}`} />
              </div>
              <div className="image-caption">
                <em>author: art initialized by {artAuthor}</em>
              </div>
              {artQuote && (
                <div className="quote">
                  <br />
                  {artQuote}
                  <span className="author">- {quoteHelp === 0 || !quoteHelp ? artAuthor : `${artAuthor} & AI`}</span>
                </div>
              )}
            </div>
          ) : null)}
      </div>
    );
  }
};

function CharacterCounter({
  count,
  maxCount,
}: {
  count: number;
  maxCount: number;
}) {
  return (
    <div className="character-counter">
      {count} / {maxCount}
    </div>
  );
}

export default ArtOfTheDay;
