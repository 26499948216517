import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { url as apiUrl } from '../../../api';

const AddRecipe = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ingredients, setIngredients] = useState<{ name: string; quantity: string }[]>([]);
  const [instructions, setInstructions] = useState<string[]>([]);
  const [difficulty, setDifficulty] = useState('');
  const [time, setTime] = useState('');
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  // Temporary state for adding ingredients and instructions
  const [ingredientName, setIngredientName] = useState('');
  const [ingredientQuantity, setIngredientQuantity] = useState('');
  const [instructionStep, setInstructionStep] = useState('');

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const addIngredient = () => {
    if (ingredientName && ingredientQuantity) {
      setIngredients([...ingredients, { name: ingredientName, quantity: ingredientQuantity }]);
      setIngredientName('');
      setIngredientQuantity('');
    } else {
      toast.error('Please enter both ingredient name and quantity');
    }
  };

  const removeIngredient = (index: number) => {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(newIngredients);
  };

  const addInstruction = () => {
    if (instructionStep) {
      setInstructions([...instructions, instructionStep]);
      setInstructionStep('');
    } else {
      toast.error('Please enter an instruction step');
    }
  };

  const removeInstruction = (index: number) => {
    const newInstructions = instructions.filter((_, i) => i !== index);
    setInstructions(newInstructions);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check for duplicate images
      const isDuplicate = selectedImages.some((img) => img.name === file.name && img.size === file.size);
      if (isDuplicate) {
        toast.error('This image has already been selected.');
        return;
      }

      setSelectedImages([...selectedImages, file]);
    }
  };

  const moveImageUp = (index: number) => {
    if (index === 0) return; // Can't move up the first image
    const newImages = [...selectedImages];
    [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
    setSelectedImages(newImages);
  };

  const moveImageDown = (index: number) => {
    if (index === selectedImages.length - 1) return; // Can't move down the last image
    const newImages = [...selectedImages];
    [newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]];
    setSelectedImages(newImages);
  };

  const removeImage = (index: number) => {
    const newImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(newImages);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    if (descriptionRef.current) {
      descriptionRef.current.style.height = 'auto'; // reset height
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // set to scroll height
    }
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = 'auto'; // reset height
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // set to scroll height
    }
  }, [description]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = token ? { 'x-auth-token': token } : {};

      const recipeData = {
        title,
        description,
        difficulty,
        time,
        ingredients,
        instructions,
      };

      // First, create the recipe
      const response = await axios.post(`${apiUrl}/api/recipes`, recipeData, { headers });

      const newRecipeId = response.data.id || response.data._id; // Adjust according to the API response

      // Now, upload images if any
      if (selectedImages.length > 0) {
        for (const image of selectedImages) {
          const formData = new FormData();
          formData.append('image', image);

          await axios.post(`${apiUrl}/api/recipes/image/${newRecipeId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-auth-token': token,
            },
          });
        }
      }

      toast.success('Recipe added successfully!');

      // Clear form fields
      setTitle('');
      setDescription('');
      setIngredients([]);
      setInstructions([]);
      setDifficulty('');
      setTime('');
      setSelectedImages([]);
    } catch (error) {
      toast.error('Failed to add recipe. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6 p-6 max-w-2xl mx-auto">
      {/* Title */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>

      {/* Description */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Description:</label>
        <textarea
          ref={descriptionRef}
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Description"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 resize-none"
          rows={1}
        ></textarea>
      </div>

      {/* Ingredients */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">Ingredients:</label>
        <div className="flex gap-2">
          <input
            type="text"
            value={ingredientName}
            onChange={(e) => setIngredientName(e.target.value)}
            placeholder="Ingredient Name"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <input
            type="text"
            value={ingredientQuantity}
            onChange={(e) => setIngredientQuantity(e.target.value)}
            placeholder="Quantity"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <button
            type="button"
            onClick={addIngredient}
            className="mt-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Add
          </button>
        </div>
        {/* List of ingredients */}
        {ingredients.length > 0 && (
          <ul className="mt-2">
            {ingredients.map((ingredient, index) => (
              <li key={index} className="flex items-center py-1 gap-2">
                <input
                  type="text"
                  value={ingredient.name}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].name = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Ingredient Name"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <input
                  type="text"
                  value={ingredient.quantity}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].quantity = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Quantity"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <button
                  type="button"
                  onClick={() => removeIngredient(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Instructions */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">Instructions:</label>
        <div className="flex gap-2">
          <input
            type="text"
            value={instructionStep}
            onChange={(e) => setInstructionStep(e.target.value)}
            placeholder="Instruction Step"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <button
            type="button"
            onClick={addInstruction}
            className="mt-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Add
          </button>
        </div>
        {/* List of instructions */}
        {instructions.length > 0 && (
          <ol className="list-decimal mt-2 ml-5">
            {instructions.map((instruction, index) => (
              <li key={index} className="flex items-center py-1 gap-2">
                <input
                  type="text"
                  value={instruction}
                  onChange={(e) => {
                    const newInstructions = [...instructions];
                    newInstructions[index] = e.target.value;
                    setInstructions(newInstructions);
                  }}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <button
                  type="button"
                  onClick={() => removeInstruction(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ol>
        )}
      </div>

      {/* Difficulty */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Difficulty:</label>
        <input
          type="number"
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
          placeholder="Difficulty (1-10)"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          min="1"
          max="10"
        />
      </div>

      {/* Time */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Time:</label>
        <input
          type="text"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          placeholder="Time (e.g., 30 mins)"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>

      {/* Upload Images */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Upload Images:</label>
        <div className="flex items-center gap-4">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="mt-1 block w-full"
          />
        </div>
        {/* Image Previews with rearrange options */}
        {selectedImages.length > 0 && (
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-700 mb-2">Image Previews:</p>
            <div className="flex flex-col gap-4">
              {selectedImages.map((image, index) => (
                <div key={index} className="flex items-center gap-4">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Selected ${index}`}
                    className="w-32 h-32 object-cover rounded-md shadow-md"
                  />
                  <div className="flex flex-col gap-2">
                    <button
                      type="button"
                      onClick={() => moveImageUp(index)}
                      className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                        index === 0 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={index === 0}
                    >
                      Move Up
                    </button>
                    <button
                      type="button"
                      onClick={() => moveImageDown(index)}
                      className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                        index === selectedImages.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={index === selectedImages.length - 1}
                    >
                      Move Down
                    </button>
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Submit Button */}
      <button type="submit" className="bg-green-500 text-white py-3 px-6 rounded hover:bg-green-600">
        Add Recipe
      </button>
    </form>
  );
};

export default AddRecipe;
