import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { setHeaders } from 'src/api';
import { url } from "../../api/index.js";

interface UserStats {
	username: string;
	oGquoteCount: number;
	longestStreak: {
		count: number;
		streakStartDate: string | null;
		streakEndDate: string | null;
	};
	importantNumbers: number[];
	totalAOTDs: number;
	confirmedRecipeMakes: number;
	signupRank: number;
}

const UserStatsTable: React.FC = () => {
	const [userStats, setUserStats] = useState<UserStats[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchUserStats = async () => {
			try {
				const headers = setHeaders();
				const response = await axios.get(url + '/api/stats/users', headers);
				setUserStats(response.data);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching user stats:', error);
				setLoading(false);
			}
		};

		fetchUserStats();
	}, []);

	if (loading) {
		return <div className="p-4 text-gray-600">Loading...</div>;
	}

	return (
		<div className="max-w-full overflow-x-auto">
			<table className="min-w-full border border-gray-300 divide-y divide-gray-200">
				<thead>
					<tr className="bg-black bg-opacity-10">
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">Username</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">OG qts</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">Mx strk</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">Nmbrs</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">AOTDs</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">Rcp mk</th>
						<th scope="col" className="px-4 py-3 text-left text-sm font-semibold text-gray-800 whitespace-nowrap border border-gray-300">Rnk</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200">
					{userStats.map((user) => (
						<tr
							key={user.username}
							className="hover:opacity-90 transition-opacity"
						>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.username}</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.oGquoteCount}</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.longestStreak.count}</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">
								{user.importantNumbers.length > 0 ? user.importantNumbers.join('|') : '-'}
							</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.totalAOTDs ? user.totalAOTDs : "-"}</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.confirmedRecipeMakes}</td>
							<td className="px-4 py-2 text-sm text-gray-700 whitespace-nowrap opacity-90 border border-gray-300">{user.signupRank}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default UserStatsTable;
