import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faDoorClosed, faSignOut, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { keyframes } from 'styled-components';


import "./site_header.scss";
import styled from "styled-components";
// import logo from "../../assets/logos/nassos.svg";
import logo2 from "../../assets/logos/bolt.png";
// import fullLogo from "../../assets/logos/modern/full.png";
// import gayLogo from "../../assets/logos/modern/gay.png";
// import outlineLogo from "../../assets/logos/modern/outline.png";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/actions/authActions";
import { RootState } from "../../store/reducers/types";
import BasicButton from "../basics/BasicButton";

interface BlockProps {
  active: boolean;
  open?: boolean;
  pressed?: boolean;
}

interface LinkStyleProps {
  active?: boolean;
}

interface HeaderProps {
  bgColor?: string; // Add other props as necessary
}

const fade = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0;  }
`;

const Background = styled.div<{z_idx:boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index:${({z_idx}) => z_idx ? -99 : 1};
  background: linear-gradient(to right, hsl(0, 70%, 85%), hsl(60, 70%, 85%), hsl(120, 70%, 85%), hsl(180, 70%, 85%), hsl(240, 70%, 85%), hsl(300, 70%, 85%), hsl(360, 70%, 85%));
  opacity: 1;
  animation: ${fade} 10s linear forwards;
`;

const HeaderD = styled.header<HeaderProps>`
  position: relative;
  background: ${({ bgColor }) => bgColor};
  z-index: ${({ bgColor }) => bgColor ? 3 : -99};
`;

const Header: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const state = useSelector((state: RootState) => state);
  console.log(state);
  const user = useSelector((state: RootState) => state.auth);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );
  const [openDropdown, setOpenDropdown] = useState("");
  const [pressedDropdown, setPressedDropdown] = useState("");
  const [signOutIsHovered, setSignOutIsHovered] = useState(false);

  const blocks = [
    {
      name: "home",
      link: "/",
      // subItems: [
      //   {
      //     name: "art of the day",
      //     link: "/art-of-the-day",
      //   },
      //   {
      //     name: "gallery",
      //     link: "/art",
      //   },
      // ],
    },
    {
      name: "art",
      link: "",
      subItems: [
        {
          name: "art of the day",
          link: "/art-of-the-day",
        },
        {
          name: "n450s gallery",
          link: "/art",
        },
      ],
    },
    { name: "store", link: "/marketplace" },
    { name: "recipes", link: "/cookbook" },
  ];

  const navigate = useNavigate();
  function handleClick() {
    navigate("/");
  }
  console.log(user);
  console.log(currentPath);
  console.log(activeIndex);

  const handleSignOut = () => {
    dispatch(signOut());
  };

  const [bgColor, setBgColor] = useState('');
  const [z_idx, setZ_idx] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      let hue;
      do {
        hue = Math.floor(Math.random() * 360);
      } while (hue >= 90 && hue <= 150); // Exclude green hues from 90 to 150
      const saturation = 90;
      const lightness = 95;
      setBgColor(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
      setZ_idx(true);
    }, 0);
  }, []);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  if (location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/party") {
    return null;
  }
  return (
    <div className="header-container">
      <HeaderD className="my-header" bgColor={bgColor}>
      <Background  z_idx={z_idx}/>
        <nav className="header-nav">
          <div className="logo-container">
            <img
              className="my-logo"
              src={logo2}//src={outlineLogo}
              onClick={handleClick}
              alt="logo"
            />
            {/* gay month special */}
          </div>
          <ul className="header-ul">
            {blocks.map(({ name, link, subItems }) => {
              if (subItems) {
                // Render dropdown menu for items with subItems (e.g., "art")
                const isOpen = openDropdown === name;
                const isActive = subItems.some(
                  (subItem) => subItem.link === currentPath
                );

                return (
                  <div className="dropdown-div" key={name}>
                    <DropdownWrapper
                      active={true}
                      pressed={pressedDropdown === name}
                    >
                      <DropdownButton
                        // onClick={() => setOpenDropdown(isOpen ? "" : name)}
                        onClick={() => {
                          setPressedDropdown(
                            pressedDropdown === name ? "" : name
                          );
                          // setOpenDropdown(isOpen ? "" : name)
                        }}
                        active={isActive}
                        open={isOpen}
                        pressed={pressedDropdown === name}
                      >
                        {name}{" "}
                        <FontAwesomeIcon
                          style={{
                            marginRight: "3px",
                            alignItems: "center",
                            color: "#3f3f3fff",
                          }}
                          icon={
                            pressedDropdown === name ? faAngleUp as any : faAngleDown as any
                          }
                          aria-hidden="true"
                        />
                      </DropdownButton>

                      <DropdownBlock
                        pressed={pressedDropdown === name}
                        active={isActive}
                        className={"dropdown"}
                      >
                        {subItems.map((subItem, index) => (
                          <LinkStyle key={subItem.name} to={subItem.link}>
                            <BlockSub
                              className={"block" + subItem.name + " dropdown-item"}
                              onClick={() => {
                                setCurrentPath(subItem.link);
                                setOpenDropdown(subItem.name);
                              }}
                              style={{
                                borderBottom: index === subItems.length - 1 ? 'none' : '1px solid #ccc',
                                
                              }}
                              active={subItem.link === currentPath}
                              // open= {isOpen}
                            >
                              {subItem.name}
                            </BlockSub>
                          </LinkStyle>
                        ))}
                      </DropdownBlock>
                    </DropdownWrapper>
                  </div>
                );
              } else {
                // Render regular navigation items
                return (
                  <LinkStyleSingle
                    // style={{ alignItems: "center", margin: "5px" }}
                    key={name}
                    to={link}
                  >
                    <Block
                      className={"block" + name}
                      onClick={() => {
                        setCurrentPath(link);
                        setOpenDropdown("");
                      }}
                      active={link === currentPath}
                    >
                      {name}
                    </Block>
                  </LinkStyleSingle>
                );
              }
            })}
            <div className="auth">
              {user._id ? (
                <>
                  <LinkStyleAuth to="/profile">
                    <BlockAuth
                      onClick={() => {
                        setCurrentPath("/profile");
                        setOpenDropdown("");
                      }}
                      active={currentPath === "/profile"}
                    >
                      profile
                    </BlockAuth>
                  </LinkStyleAuth>
                  <button
                    className="sign-out"
                    onClick={() => {
                      handleSignOut();
                      setActiveIndex(0);
                    }}
                    onMouseEnter={() => setSignOutIsHovered(true)}
                    onMouseLeave={() => setSignOutIsHovered(false)}
                  >
                    {/* {signOutIsHovered ? `:(:(:(:(` : "logout:("} */}
                    {/* {signOutIsHovered ? `😢` : "logout:("} */}
                    {signOutIsHovered ? `😢` : "logout"}
                   </button> 
                  {/* <BasicButton onClick={handleSignOut} text="logout" hoverText="😢" bgColor="#fa6089e1" fontWeight="normal" /> */}
                </>
              ) : (
                <>
                  <LinkStyleAuth to="/register">
                    <BlockAuth
                      onClick={() => setActiveIndex(0)}
                      active={currentPath === "/register"}
                    >
                      signup
                    </BlockAuth>
                  </LinkStyleAuth>
                  <LinkStyleAuth to="/login">
                    <BlockAuth
                      onClick={() => setActiveIndex(0)}
                      active={currentPath === "/login"}
                      // className="log-in"
                    >
                      login
                    </BlockAuth>
                  </LinkStyleAuth>
                </>
              )}
            </div>
          </ul>
        </nav>
      </HeaderD>
    </div>
  );
};

const Block = styled.div<BlockProps>`
  background-color: ${(props) => (props.active ? "#9beece" : "white")};
  transition: padding 0.2s;
  &:hover {
    background-color: ${(props) => (props.active ? "#9beece" : "#d4f9eb")};
    padding: 3px;
  }
  @media (max-width: 700px) {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    &:hover {
      background-color: ${(props) => (props.active ? "#9beece" : "#d4f9eb")};
      padding: 10px;
    }
  }
  /* margin: 10px; */
`;

const DropdownButton = styled.button<BlockProps>`
  background-color: ${(props) => (props.active ? "#9beece" : "white")};
  box-shadow: ${(props) => (props.pressed ? "2px 4px rgba(0, 0, 0, 0.7)" : "")};
  transition: padding 0.2s;
  font-size: 1rem;
  color: ${(props) => (props.active ? "black" : "#3f3f3fff")};
  padding: ${(props) => (props.pressed ? "3px" : "0px")};
  border: ${(props) => (props.pressed ? "solid 1px #466a5d" : "0px")};
  /* cursor: pointer; */
  align-items: center;
  /* margin: 10px; */

  &:hover {
    background-color: ${(props) => (props.active ? "#9beece" : "#d4f9eb")};
    padding: ${(props) => (props.pressed ? "4px" : "3px")};
    color: black;
  }

  @media (max-width: 700px) {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    padding: 10px;
    &:hover {
      background-color: ${(props) => (props.active ? "#9beece" : "#d4f9eb")};
      padding: 10px;
    }
  }

  /* margin: 10px; */
`;

const BlockSub = styled.div<BlockProps>`
  background-color: ${(props) => (props.active ? "#466a5d" : "white")};
  color: ${(props) => (props.active ? "#dcdcdc" : "#383838")};
  transition: padding 0.2s;
  &:hover {
    /* background-color: ${(props) =>
      props.active ? "#9beece" : "#d4f9eb"}; */
    background-color: ${(props) => (props.active ? "#466a5d" : "#9beece")};
    padding: 3px;
  }
  @media (max-width: 700px) {
    padding: 10px;
    /* width:100%; */
    &:hover {
      padding: 10px;
    }
  }
  /* margin: 10px; */
`;

const DropdownBlock = styled.div<BlockProps>`
  @keyframes slideIn {
    from {
      transform: translateY(-20%);
    }
    to {
      transform: translateY(30%);
    }
    to {
      transform: translateY(0%);
    }
  }
  z-index: 5;
  display: ${(props) => (props.pressed ? "block" : "none")};
  /* display: ${(props) => (props.open ? "block" : "none")}; */
  /* padding: 3px; */
  transition: padding 0.2s;
  transition: 0.3s;
  /* border: 5px; */
  box-shadow: 2px 4px rgba(0, 0, 0, 0.7);
  &:hover {
    background-color: ${(props) => (props.active ? "#466a5d" : "#9beece")};
    color: ${(props) => (props.active ? "#dcdcdc" : "#383838")};
    padding: 7px;
    box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  }
  position: absolute;
  /* top: 100%; */
  width: 9rem;
  /* top: 50%; */
  /* left: -90%; */
  /* transform: translate(-50%, 50%); */
  /* right: 10px; */
  align-items: center;
  z-index: 10;
  animation: slideIn 0.3s ease-out;
  @media (max-width: 700px) {
    left: -50%;
    display: ${(props) => (props.pressed ? "block" : "none")};
  }
`;

const DropdownWrapper = styled.div<BlockProps>`
  position: relative;
  &:hover > div.dropdown {
    display: block;
    /* scale: 110%; */
    @media (max-width: 700px) {
      /* display: none; */
      display: ${(props) => (props.pressed ? "block" : "none")};
      /* width:100%; */
    }
  }
`;

const BlockAuth = styled.div<BlockProps>`
  /* background-color: #fb8ff9; */
  margin: 10px;
  /* border: ${(props) => (props.active ? "3px solid transparent" : "3px solid transparent")}; */
  font-weight: ${(props) => (props.active ? 800 : 200)};
  /* border: ${(props) => (props.active ? "3px" : "0px")}; */
  font-size: ${(props) => (props.active ? "1.1rem" : "1rem")};
  transition: all 0.2s;
  /* width: 60px; */
  /* display: list-item; */
  &:hover {
    font-weight: ${(props) => (props.active ? 900 : 400)};
    font-size: ${(props) => (props.active ? "1.1rem" : "1rem")};
    filter: drop-shadow(0px 0px 1px #000000);
    /* width: initial; */
  }
  @media (max-width: 700px) {
    padding: 10px;
    /* width:100%; */
    &:hover {
      padding: 10px;
    }
  }
`;

const LinkStyleAuth = styled(NavLink)<LinkStyleProps>`
  color: ${(props) => (props.active ? "black" : "#3f3f3fff")};
  text-decoration: none;
  font-size: medium;
  /* font-weight: 200; */
  min-width: 75px;
  /* display: inline-block; */
  &:hover {
    color: black;
    /* font-weight: 400; */
    /* filter: drop-shadow(0px 0px 1px #000000); */
  }
  &:active {
    color: #ff4be1;
    /* font-weight: 400; */
  }
  &:focus {
    color: black;
  }
`;

const LinkStyle = styled(NavLink)<LinkStyleProps>`
  color: ${(props) => (props.active ? "black" : "#3f3f3fff")};
  text-decoration: none;

  &:hover {
    color: black;
  }
  &:active {
    color: #ff4be1;
  }
  &:selected {
    background-color: #9beece;
  }
  &:focus {
    color: black;
  }
`;
const LinkStyleSingle = styled(NavLink)<LinkStyleProps>`
  color: ${(props) => (props.active ? "black" : "#3f3f3fff")};
  text-decoration: none;
  align-items: center;
  margin: 10px;

  &:hover {
    color: black;
  }
  &:active {
    color: #ff4be1;
  }
  &:selected {
    background-color: #9beece;
  }
  &:focus {
    color: black;
  }
  @media (max-width: 700px) {
    margin: 0px;
  }
`;

export default Header;
