import React, { useState } from 'react';
import { RecipeInfo } from '../loaders/useInfo';
import '../styles/recipe_make.css';
import { url as apiUrl, setHeaders } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faCheck, faCheckCircle, faCircleCheck, faDeleteLeft, faEye, faIdBadge, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import axios from 'axios';
import { toast } from 'react-toastify';
import BasicButton from 'src/components/basics/BasicButton';

export type RecipeMake = {
	userId: string;
	id: string;
	author: string;
	comment: string;
	date: Date;
	image: string;
	isConfirmed: boolean;
}

const RecipeMakeCard: React.FC<{ recipeInfo: RecipeInfo, recipeMake: RecipeMake }> = ({ recipeInfo, recipeMake }) => {
	const user = useSelector((state: RootState) => state.auth);
	const [isEditing, setIsEditing] = useState(false);

	const handleEditToggle = () => {
		setIsEditing(!isEditing);
	};

	const handleConfirm = async () => {
		try {
			const headers = setHeaders();
			const res = await axios.put(`${apiUrl}/api/recipes/${recipeInfo.id}/comments/${recipeMake.id}/confirm`, {}, headers);
			if (res.status !== 200) {
				throw new Error('Failed to confirm comment');
			}
			toast.success('Comment confirmed');
		} catch (error) {
			console.error('Error confirming comment:', error);
			toast.error('Failed to confirm comment');
		}
	};

	const handleDelete = async () => {
		try {
			const headers = setHeaders();
			const res = await axios.delete(`${apiUrl}/api/recipes/${recipeInfo.id}/comments/${recipeMake.id}`, headers);
			if (res.status !== 200) {
				throw new Error('Failed to delete comment');
			}
			toast.success('Comment deleted successfully');
		} catch (error) {
			console.error('Error deleting comment:', error);
			toast.error('Failed to delete comment');
		}
	};

	return (
		<div className='flex flex-col'>
			<div className='im_res_div_make'>
				<div className="flex items-center">
					<div className="recipe-make-user-small">{recipeMake.author}</div>
					{user.role === 'admin' && (
						<FontAwesomeIcon icon={faEye} onClick={handleEditToggle} className="cursor-pointer ml-2" />
					)}
				</div>
				<img src={`${apiUrl}/api/recipes/images/${recipeInfo.id}/${recipeMake.image}`} alt={recipeMake.author} className="recipe-make-thumbnail" />

				<div className="recipe-make-content">
					<div>
						<div className="recipe-make-comment-small">
							{recipeMake.comment}
							{recipeMake.isConfirmed && (
								<span className='text-blue-400 text-xs border text-nowrap rounded-full p-0.5'>
									<FontAwesomeIcon className="pr-0.5" icon={faCircleCheck} />
									Confirmed
								</span>
							)}
						</div>
					</div>
				</div>

				{/* text to the bottom left 0.5rem */}
				<span className="text-xs text-gray-400 text-left">
					{new Date(recipeMake.date).toDateString()}
				</span>
			</div>
			<div className='flex flex-col'>
				{isEditing && (
					<>
						{!recipeMake.isConfirmed && <BasicButton onClick={handleConfirm} text="Confirm" icon={faCheck} bgColor="#87c3f9" className="m-1 text-sm bg-blue-400" />}
						<BasicButton onClick={handleDelete} text="Delete" icon={faTrashAlt} fontWeight='800' bgColor="#f87888" className=" m-1 text-sm" />
					</>
				)}
			</div>
		</div>
	);
};


export default RecipeMakeCard;
