import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { url as apiUrl } from '../../../api'; // Adjust this import
import { RecipeInfo } from '../loaders/useInfo';
import BasicButton from 'src/components/basics/BasicButton';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const EditRecipe: React.FC<{ recipeInfo: RecipeInfo }> = ({ recipeInfo }) => {
  const [title, setTitle] = useState(recipeInfo.title || '');
  const [description, setDescription] = useState(recipeInfo.description || '');
  const [ingredients, setIngredients] = useState(
    recipeInfo.ingredients || []
  );
  const [instructions, setInstructions] = useState(
    recipeInfo.instructions || []
  );
  const [difficulty, setDifficulty] = useState(recipeInfo.difficulty || '');
  const [time, setTime] = useState(recipeInfo.time || '');
  const [existingImages, setExistingImages] = useState(recipeInfo.images || []);
  const [newImages, setNewImages] = useState<File[]>([]);

  // Temporary state for adding ingredients and instructions
  const [ingredientName, setIngredientName] = useState('');
  const [ingredientQuantity, setIngredientQuantity] = useState('');
  const [instructionStep, setInstructionStep] = useState('');

  const descriptionRef = useRef<HTMLTextAreaElement | null>(null);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    if (descriptionRef.current) {
      descriptionRef.current.style.height = 'auto'; // reset height
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // set to scroll height
    }
  };

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.style.height = 'auto'; // reset height
      descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`; // set to scroll height
    }
  }, [description]);

  // Handle adding ingredients
  const addIngredient = () => {
    if (ingredientName && ingredientQuantity) {
      setIngredients([...ingredients, { name: ingredientName, quantity: ingredientQuantity }]);
      setIngredientName('');
      setIngredientQuantity('');
    } else {
      toast.error('Please enter both ingredient name and quantity');
    }
  };

  const removeIngredient = (index: number) => {
    const newIngredients = ingredients.filter((_, i) => i !== index);
    setIngredients(newIngredients);
  };

  // Handle adding instructions
  const addInstruction = () => {
    if (instructionStep) {
      setInstructions([...instructions, instructionStep]);
      setInstructionStep('');
    } else {
      toast.error('Please enter an instruction step');
    }
  };

  const removeInstruction = (index: number) => {
    const newInstructions = instructions.filter((_, i) => i !== index);
    setInstructions(newInstructions);
  };

  // Handle existing images
  const deleteExistingImage = async (imageUrl: string) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${imageUrl}`, {
        headers: {
          'x-auth-token': token,
        },
      });
      const updatedImages = existingImages.filter((img) => img !== imageUrl);
      setExistingImages(updatedImages);
      toast.success('Image deleted successfully');
    } catch (error) {
      toast.error('Failed to delete image. Please try again.');
    }
  };

  const moveExistingImageUp = (index: number) => {
    if (index === 0) return;
    const newImages = [...existingImages];
    [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
    setExistingImages(newImages);
  };

  const moveExistingImageDown = (index: number) => {
    if (index === existingImages.length - 1) return;
    const newImages = [...existingImages];
    [newImages[index + 1], newImages[index]] = [newImages[index], newImages[index + 1]];
    setExistingImages(newImages);
  };

  // Handle new images
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const isDuplicate = newImages.some((img) => img.name === file.name && img.size === file.size);
      if (isDuplicate) {
        toast.error('This image has already been selected.');
        return;
      }
      setNewImages([...newImages, file]);
    }
  };

  const moveNewImageUp = (index: number) => {
    if (index === 0) return;
    const newImgs = [...newImages];
    [newImgs[index - 1], newImgs[index]] = [newImgs[index], newImgs[index - 1]];
    setNewImages(newImgs);
  };

  const moveNewImageDown = (index: number) => {
    if (index === newImages.length - 1) return;
    const newImgs = [...newImages];
    [newImgs[index + 1], newImgs[index]] = [newImgs[index], newImgs[index + 1]];
    setNewImages(newImgs);
  };

  const removeNewImage = (index: number) => {
    const newImgs = newImages.filter((_, i) => i !== index);
    setNewImages(newImgs);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const headers = token ? { 'x-auth-token': token } : {};
    
    // extract filenames from existing image URLs
    const imageFilenames = existingImages.map((imageUrl) => {
      const parts = imageUrl.split('/');
      return parts[parts.length - 1]; // get the last part of the URL
    });

    try {
      // Update recipe details
      await axios.put(
        `${apiUrl}/api/recipes/${recipeInfo.id}`,
        {
          title,
          description,
          ingredients,
          instructions,
          difficulty,
          time,
          images: imageFilenames, // use filenames instead of full URLs
        },
        { headers }
      );

      // Upload new images
      if (newImages.length > 0) {
        for (const image of newImages) {
          const formData = new FormData();
          formData.append('image', image);

          await axios.post(`${apiUrl}/api/recipes/image/${recipeInfo.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-auth-token': token,
            },
          });
        }
      }

      toast.success('Recipe updated successfully!');
    } catch (error) {
      toast.error('Failed to update recipe. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6 p-6 max-w-2xl mx-auto bg-white shadow-md rounded-md">
      {/* Title */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>

      {/* Description */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Description:</label>
        <textarea
          ref={descriptionRef}
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Description"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 resize-none"
          rows={1}
        ></textarea>
      </div>

      {/* Ingredients */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Ingredients:</label>
        <div className="flex gap-2">
          <input
            type="text"
            value={ingredientName}
            onChange={(e) => setIngredientName(e.target.value)}
            placeholder="Ingredient Name"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <input
            type="text"
            value={ingredientQuantity}
            onChange={(e) => setIngredientQuantity(e.target.value)}
            placeholder="Quantity"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <button
            type="button"
            onClick={addIngredient}
            className="mt-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Add
          </button>
        </div>
        {/* List of ingredients */}
        {ingredients.length > 0 && (
          <ul className="mt-2">
            {ingredients.map((ingredient, index) => (
              <li key={index} className="flex items-center py-1 gap-2">
                <input
                  type="text"
                  value={ingredient.name}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].name = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Ingredient Name"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <input
                  type="text"
                  value={ingredient.quantity}
                  onChange={(e) => {
                    const newIngredients = [...ingredients];
                    newIngredients[index].quantity = e.target.value;
                    setIngredients(newIngredients);
                  }}
                  placeholder="Quantity"
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <button
                  type="button"
                  onClick={() => removeIngredient(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Instructions */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Instructions:</label>
        <div className="flex gap-2">
          <input
            type="text"
            value={instructionStep}
            onChange={(e) => setInstructionStep(e.target.value)}
            placeholder="Instruction Step"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          />
          <button
            type="button"
            onClick={addInstruction}
            className="mt-1 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Add
          </button>
        </div>
        {/* List of instructions */}
        {instructions.length > 0 && (
          <ol className="list-decimal mt-2 ml-5">
            {instructions.map((instruction, index) => (
              <li key={index} className="flex items-center py-1 gap-2">
                <input
                  type="text"
                  value={instruction}
                  onChange={(e) => {
                    const newInstructions = [...instructions];
                    newInstructions[index] = e.target.value;
                    setInstructions(newInstructions);
                  }}
                  className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
                <button
                  type="button"
                  onClick={() => removeInstruction(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ol>
        )}
      </div>

      {/* Difficulty */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Difficulty:</label>
        <input
          type="number"
          value={difficulty}
          onChange={(e) => setDifficulty(e.target.value)}
          placeholder="Difficulty (1-10)"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          min="1"
          max="10"
        />
      </div>

      {/* Time */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Time:</label>
        <input
          type="text"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          placeholder="Time (e.g., 30 mins)"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
        />
      </div>

      {/* Existing Images */}
      {existingImages.length > 0 && (
        <div>
          <p className="text-sm font-medium text-gray-700 mb-2">Existing Images:</p>
          <div className="flex flex-col gap-4">
            {existingImages.map((imageUrl, index) => (
              <div key={index} className="flex items-center gap-4">
                <img
                  src={imageUrl}
                  alt={`Existing ${index}`}
                  className="w-32 h-32 object-cover rounded-md shadow-md"
                />
                <div className="flex flex-col gap-2">
                  <button
                    type="button"
                    onClick={() => moveExistingImageUp(index)}
                    className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                      index === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={index === 0}
                  >
                    Move Up
                  </button>
                  <button
                    type="button"
                    onClick={() => moveExistingImageDown(index)}
                    className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                      index === existingImages.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={index === existingImages.length - 1}
                  >
                    Move Down
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteExistingImage(imageUrl)}
                    className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* New Images */}
      <div>
        <label className="block text-sm font-medium text-gray-700">Upload New Images:</label>
        <div className="flex items-center gap-4">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="mt-1 block w-full"
          />
        </div>
        {/* New Image Previews with rearrange options */}
        {newImages.length > 0 && (
          <div className="mt-4">
            <p className="text-sm font-medium text-gray-700 mb-2">New Image Previews:</p>
            <div className="flex flex-col gap-4">
              {newImages.map((image, index) => (
                <div key={index} className="flex items-center gap-4">
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`New ${index}`}
                    className="w-32 h-32 object-cover rounded-md shadow-md"
                  />
                  <div className="flex flex-col gap-2">
                    <button
                      type="button"
                      onClick={() => moveNewImageUp(index)}
                      className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                        index === 0 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={index === 0}
                    >
                      Move Up
                    </button>
                    <button
                      type="button"
                      onClick={() => moveNewImageDown(index)}
                      className={`bg-gray-200 text-gray-700 py-1 px-2 rounded hover:bg-gray-300 ${
                        index === newImages.length - 1 ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      disabled={index === newImages.length - 1}
                    >
                      Move Down
                    </button>
                    <button
                      type="button"
                      onClick={() => removeNewImage(index)}
                      className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Submit Button */}
      {/* <button type="submit" className="bg-green-500 text-white py-3 px-6 rounded hover:bg-green-600">
        Save Changes
      </button> */}
      <BasicButton text="Save Changes" bgColor="#34D399" icon={faSave} />
    </form>
  );
};

export default EditRecipe;
