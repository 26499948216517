import { React, Component, useEffect } from "react";
// import "./home.css";
import styled from "styled-components";
import { faPencil, faSmile } from '@fortawesome/free-solid-svg-icons';
// import axios from "axios";
import { useState, setState } from "react";
import { useSelector } from "react-redux";
import ArtOfTheDay from "../art_of_the_day/art_of_the_day";
import { Link } from "react-router-dom";
import { logPageView, setUser } from '../../analytics/GoogleAnalytics';
import AotdAuthorsLeaderboard from "../stats_page/AOTDLeaderboard";
import Modal from "../verification/modal";
import axios from "axios";
import { toast } from "react-toastify";
import { setHeaders, url } from "src/api";
import VerificationComponent from "../verification/verification";
import BasicButton from "../basics/BasicButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function getRoles(str) {
  // convert the input string to an array by splitting at each comma
  var arr = str.split(",");
  // return the array
  return arr;
}

const HomePage = () => {
  const user = useSelector((state) => state.auth);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [hasEmoji, setHasEmoji] = useState(true);

  useEffect(() => {
    logPageView();
    if (user.username) {
      setUser(user.username);
      // fetch the emoji status from the server only if the user is logged in
      const headers = setHeaders();
      axios.get(url + '/api/user/has-emoji', headers)
        .then(response => {
          setHasEmoji(response.data.hasEmoji);
        })
        .catch(error => {
          // console.error("Error fetching emoji status:", error);
        });
    }
  }, [user.username]);

  useEffect(() => {
    document.title = `NΛSOS`;
    checkVerificationStatus();
  }, [user.username]);

  const checkVerificationStatus = async () => {
    console.log('API URL:', url);
    console.log(`Environment: ${process.env.REACT_APP_SERVER_URL}`)
    try {
      const response = await axios.get(`${url}/api/verify/huh`, {
        headers: {
          'x-auth-token': localStorage.getItem('token'), // Assuming you're storing the token in localStorage
        },
      });

      if (response.status === 200 && !response.data.verified) {
        setShowVerificationModal(true);
        // toast in the botom left
        toast.info(
          'Your email is not verified. Please press the verify button to verify your email',
          {
            position: 'bottom-left',
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

      }
    } catch (error) {
      console.error('Error checking verification status:', error);
    }
  };


  return (
    <HomeContainer>
      <div>
        <h1>This is the NΛSOS website</h1>
        <h3><i>The website is under construction and will stay like this for some time...</i></h3>
        {user._id ? (
          <h2>Your rank is "{user.role.split(", ")}"</h2>
        ) : (
          <h2></h2>
        )}

        {showVerificationModal && (
          <VerificationComponent />
        )}
        {user._id && !hasEmoji && (
          // on click, go to the profile page
          <BasicButton className="m-[10px]" 
           onClick={() => window.location.href = "/profile"} bgColor="#ffde34" hoverBgColor="#f5ef9d" text="🙂 Add your emoji" 
          ></BasicButton>
        )}
      </div>

      <BoxDivImportant>
        <p>BADGEEES.</p>
        <p>Start collecting your badges. You will know your points ranking soon... view your badges in your <Link to="/profile">profile</Link></p>
      </BoxDivImportant>

      <AotdAuthorsLeaderboard />
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: auto;
  min-height: 100vh;
  /* background-color: #c4ffd2; */
  background-color: #b7ffae;
  text-emphasis-color: #223126;
  padding: 15px;
`;

const BoxDiv = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ffe95dbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDiv2 = styled.div`
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  background-color: #ae5dffbc;
  margin: 20px;
  justify-content: start;
`;

const BoxDivImportant = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(255, 111, 0, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #ff611dbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const BoxDivImportant2 = styled.div`
  border: 4px solid;
  padding: 5px;
  padding-left: 10px;
  box-shadow:   0px 0px 10px 2px rgba(30, 255, 0, 0.907), 5px 10px rgba(0, 0, 0, 0.7) ;
  background-color: #56ff1dbc;
  margin: 20px;
  justify-content: start;
  font-weight: 700;
  font-size: 1.5rem;
`;

const StyledParagraph = styled.p`
  color: #333; // Example color
  font-size: 1rem; // Example font size
  margin: 10px 0; // Example margin
`;

const HighlightedText = styled.p`
  color: #e672e6; // Example highlight color
  font-size: 0.9rem; // Slightly larger font size for emphasis
  margin: 10px 0;
  // font-weight: bold;
`;


class IpCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ip: null,
      country: null,
      city: null,
      isLoading: true,
      provider: null,
      error: false,
    };
  }

  componentDidMount() {
    console.log(this);
    fetch(process.env.REACT_APP_IP_API_LINK)
      .then((response) => response.json())
      .then((response) => {
        this.setState({
          ip: response.ip,
          country: response.country,
          city: response.city,
          provider: response.organization,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: true,
        });
        console.log(error);
      });
  }

  render() {
    if (this.state.isLoading) {
      return <div>Wait...</div>;
    } else if (!this.state.error) {
      if (
        this.state.provider ===
        "Ote SA (Hellenic Telecommunications Organisation)"
      ) {
        return (
          <div>
            <p>
              Your IP is {this.state.ip} and you live in {this.state.country} (
              {this.state.city}). Btw change your provider, cosmote sucks
            </p>
          </div>
        );
      } else {
        return (
          <div>
            <p>
              Your IP is {this.state.ip} and you live in {this.state.country} (
              {this.state.city}){" "}
            </p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p>There is an error and I can't find where you live</p>
        </div>
      );
    }
  }
}

export default HomePage;
