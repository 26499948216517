import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { url as apiUrl, setHeaders } from '../../../api';
import BasicButton from 'src/components/basics/BasicButton';

const AssignBadgesButton: React.FC = () => {
  // state of the response from the server
  const [response, setResponse] = React.useState<any | null>(null);

  const handleAssignBadges = async () => {
    try {
      const headers = setHeaders();
      const response = await axios.get(`${apiUrl}/api/badges/admin/assign-badges`, headers);
      if (response.status === 200) {
        toast.success('Badges assigned successfully!');
        setResponse(response.data);
        console.log('Users with badges:', response.data);
      }
    } catch (error) {
      console.error('Error assigning badges:', error);
      toast.error('Failed to assign badges');
    }
  };

  return (
    <div>
      <BasicButton
        onClick={handleAssignBadges}
        text='Assign badges'/>
      {response && (
        <div>
          <h2>Users with badges:</h2>
          {response
            .sort((a: any, b: any) => b.totalPoints - a.totalPoints)
            .map((user: any) => (
              <div key={user.username}>
                <span>{user.username} <strong>{user.totalPoints}</strong> <br/> </span>
                {user.badges.length > 0 ? (
                  <span>{user.badges.map((badge: any) => badge.code).join(', ')}</span>
                ) : (
                  <span>No badges assigned</span>
                )}
                <hr />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default AssignBadgesButton; 