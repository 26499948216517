import React, { useEffect, useState } from 'react';
import { RecipeInfo } from '../loaders/useInfo';
import '../styles/recipe.css';
import '../styles/recipe_make.css';

import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPencil, faInfoCircle, faCookieBite, faUpload, faCookie } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import EditRecipe from './edit_recipe';
import { url } from "../../../api";
// import { url as apiUrl } from '../../../api'; // Ensure this path is correct for your project structure
import { Dot, NextButton, PrevButton } from '../styles/styles';
import RecipeMakeCard, { RecipeMake } from './recipe_make_card';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BasicButton from 'src/components/basics/BasicButton';

function checkRole(str: String, searchStr: String) {
  try {
    var arr = str.split(",");
  } catch {
    return false;
  }

  for (var i = 0; i < arr.length; i++) {
    if (arr[i].trim() === searchStr) {
      return true;
    }
  }
  return false;
}

const RecipeDetails: React.FC<{
  recipeInfo: RecipeInfo,
  onClose: () => void,
}> = ({ recipeInfo, onClose }) => {
  const user = useSelector((state: RootState) => state.auth);

  const [localRecipeInfo, setLocalRecipeInfo] = useState<RecipeInfo>(recipeInfo);

  const [editClicked, setEditClicked] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [hover, setHover] = useState(false);

  const [newComment, setNewComment] = useState('');
  const [newImage, setNewImage] = useState<File | null>(null);
  const [addingComment, setAddingComment] = useState(false);

  const [captchaValue, setCaptchaValue] = useState(null);
  // const { executeRecaptcha } = useGoogleReCaptcha();


  const handleHoverIn = () => setHover(true);
  const handleHoverOut = () => setHover(false);

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % localRecipeInfo.images.length);
  };

  const goToPrevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + localRecipeInfo.images.length) % localRecipeInfo.images.length);
  };


  const editClick = () => {
    setEditClicked(!editClicked);
  };

  // Function to handle image selection
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewImage(e.target.files[0]);
    }
  };

  // Function to post the comment
  const postComment = async () => {
    if (!newComment || !newImage) {
      // toast("Please add both a comment and an image");
      toast.error("Please add both a comment and an image");
      return;
    }

    if (!captchaValue) {
      toast.error("Please complete the captcha");
      return;
    }

    // if (!executeRecaptcha) {
    //   console.log('Execute recaptcha not yet available');
    //   return;
    // }

    // const recapchaToken = await executeRecaptcha('postComment');

    const formData = new FormData();
    formData.append('comment', newComment);
    formData.append('image', newImage);
    formData.append('g-recaptcha-response', captchaValue)
    // formData.append('recaptchaV3', recapchaToken)


    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(url + `/api/recipes/${localRecipeInfo.id}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        }
      });
      // refresh comments or handle success
      setLocalRecipeInfo(prev => ({
        ...prev,
        comments: [...prev.comments, response.data]
      }));
      setAddingComment(false);
    } catch (error) {
      // log the error for debugging
      console.error("Error posting comment:", error);

      // check if the error is a MulterError for file size
      if (axios.isAxiosError(error) && error.response && typeof error.response.data === 'string' && error.response.data.includes('MulterError: File too large')) {
        // notify the user about the specific error
        toast.error("The uploaded file is too large. Please upload a smaller image.");
      } else {
        // notify the user about a general error
        toast.error("Failed to post comment. Please try again later.");
      }
    }
  };

  // Function to toggle comment addition UI
  const clickedComment = () => {
    setAddingComment(!addingComment);
  };

  const onCaptchaChange = (value: any) => {
    setCaptchaValue(value);
  };

  const scrollToEnd = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    setLocalRecipeInfo(recipeInfo);
  }, [recipeInfo]);



  if (editClicked) {
    return (
      <div>
        <EditRecipe recipeInfo={localRecipeInfo} />
        {/* <button className='edit-button' onClick={editClick}>Cancel Edit</button> */}
        <BasicButton onClick={editClick} text="Back" bgColor="#f0fbb4" className="p-2 m-2" />
      </div>
    );
  }

  return (
    <div>
      <div className='recipe'>
        <div className="recipe-header">
          <div className="recipe-title">
            {localRecipeInfo.title}
            {checkRole(user.role as String, "admin") && (
              <button className='edit-button' onClick={editClick}>
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
          </div>
          <div className="close-button" onClick={onClose}>&times;</div>
        </div>
        <div className="recipe-metadata">
          <span className="recipe-difficulty">Diff: {localRecipeInfo.difficulty}/10</span>
          <span className="recipe-time">Time: {localRecipeInfo.time}</span>
        </div>
        <div className='recipe-body'>

          <div className="recipe-description">
            {localRecipeInfo.description}
          </div>
          <div className='im-in-grid'>

            <div className='im-container' onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut} style={{ overflow: 'hidden' }}>
              <img className='image' src={localRecipeInfo.images[currentIndex]} alt="Recipe" />
              {hover && (
                <>
                  <PrevButton className='prev-button' onClick={goToPrevImage}>
                    <FontAwesomeIcon icon={faChevronLeft as any} />
                  </PrevButton>
                  <NextButton className='next-button' onClick={goToNextImage}>
                    <FontAwesomeIcon icon={faChevronRight as any} />
                  </NextButton>
                </>
              )}
              <div style={{ position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>
                {localRecipeInfo.images.map((_, index) => (
                  <Dot key={index} className={index === currentIndex ? 'active' : ''} />
                ))}
              </div>
            </div>


            <div>
              <div className='recipe-ingredients'>
                <h3>Ingredients
                  <span
                    className="info-icon font-black text-indigo-500"
                    onClick={scrollToEnd}
                  >
                    <sup><u>*</u></sup>
                  </span>
                  :</h3>
                <ul>
                  {localRecipeInfo.ingredients.map((item, index) => (
                    <li key={index}>{item.name}: {item.quantity}</li>
                  ))}
                </ul>
              </div>
              <div className='recipe-instructions'>
                <h3>Instructions:</h3>
                <ol>
                  {localRecipeInfo.instructions.map((step, index) => (
                    <li key={index}>{step}</li>
                  ))}
                </ol>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className='make-box'>


        <div className='title-makes'> Recipe Makes </div>
        <div className='subtitle-makes'> give life to the recipes! </div>
        <div className='comment_cards'>
          {localRecipeInfo.comments.length > 0 ? (
            localRecipeInfo.comments.map((make, index) => (
              <RecipeMakeCard key={index} recipeInfo={localRecipeInfo} recipeMake={make} />
            ))
          ) : (
            <p className=' font-thin '>Nothing to see here. Be the first to make this!</p>
          )}
        </div>



        <div className='recipe-details'>
          {addingComment && (
            user.token ? (
              

              <div className='comment-form'>
                <span className='font-bold text-lg m-2'
                >Add your make</span>
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Add your comment here..."
                />
                <input type="file" onChange={handleImageChange} accept="image/*" />

                <div className='recaptcha-container'>
                  <ReCAPTCHA
                    sitekey="6Lc7dRYpAAAAAA3dsXyQRQ8frsFW0VJe0X6eY9aa"
                    onChange={onCaptchaChange}
                    className='recaptcha-container'
                  />
                </div>

                <BasicButton
                  onClick={postComment}
                  icon={faCookieBite}
                  text="Post"
                  bgColor="#d3b4fb"
                  className="p-2 m-2"
                />
                <BasicButton
                  onClick={clickedComment}
                  text="Cancel"
                  bgColor="#fbb4bf"
                  className="p-2 m-2"
                />
              </div>

            ) : (
              <span> <Link to="/register">Sign up</Link> to comment!</span>
            )
          )}

          {!addingComment && (
            <div className=''>
              <BasicButton onClick={clickedComment} text="Add your make" icon={faCookie} borderColor="#dbdbdb81" borderWeight='2px' bgColor="#ed89f8ff" fontWeight='900' textColor='#ffffff' className="p-2 m-2 rounded" />
              <div className='note-makes'> If you make this, you can post it here with your thoughts! Add a picture of it and a comment. </div>
            </div>
          )
          }
        </div>

      </div>

    </div >
  );
};

export default RecipeDetails;
