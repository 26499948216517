import React, { useEffect } from "react";
import styled from 'styled-components';
import { logPageView } from "../../analytics/GoogleAnalytics";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  height: 100vh;
  padding: 40px;
  background-color: #c9a3ff;
  text-emphasis-color: #4ce074;
`;

const HeaderTitle = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 5px;
`;

const BoxDiv = styled.div`
	border: 1px solid;
	padding: 10px;
	box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
	margin:  20px;
  background-color: #ffffff;
`;

const AboutPage = () => {
  useEffect(() => {
    document.title = `NΛSOS About`;
    logPageView();
  }, []);
	return (
		<AboutContainer>
     <h1>
          About NΛSOS
      </h1>
      <BoxDiv>
          ECE @ AUTH |
          March, 2000 | 
          Nassos 
      </BoxDiv>
      <BoxDiv>
          this page is a personal site, an art portfolio and a simple recipe cookbook. Probably a marketplace and blogspot.
      </BoxDiv>
		</AboutContainer>
	);
};

export default AboutPage;