import React, { MouseEvent } from 'react';
import styles from './stats-box.module.css';

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return date.toUTCString();
}

interface UserInfoProps {
  username: string;
  userEmoji: string;
  email: string;
  role: string;
  dateSigned: string;
  lastSigned: string;
  timesSigned: number;
  chatTokensUsed: number;
  permissions: number;
  onEditButtonClick: () => void;
}

class UserInfo extends React.Component<UserInfoProps> {
  handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    this.props.onEditButtonClick();
  }



  render() {
    const { username,userEmoji, email, role, dateSigned, lastSigned, timesSigned, chatTokensUsed, permissions } = this.props;

    return (
      <div className={styles.userInfo}>
        <div className={styles.infoBox}><strong>username:</strong> {userEmoji ? <span>{userEmoji}.{username}</span> : <span>{username}</span>}</div>
        <div className={styles.infoBox}><strong>email:</strong> {email}</div>
        <div className={styles.infoBox}><strong>role:</strong> {role}</div>
        <div className={styles.infoBox}><strong>date signed:</strong> {formatDate(dateSigned)}</div>
        <div className={styles.infoBox}><strong>last signed:</strong> {formatDate(lastSigned)}</div>
        <div className={styles.infoBox}><strong>times signed:</strong> {timesSigned}</div>
        <div className={styles.infoBox}><strong>chat tokens:</strong> {chatTokensUsed}</div>
        <div className={styles.infoBox}><strong>permissions:</strong> {permissions}</div>
        <button className={styles.editButton} onClick={this.handleButtonClick}>edit</button>
      </div>
    );
  }
}
export default UserInfo;
